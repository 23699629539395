import ResourcesList from '@/components/ResourcesList/ResourcesList';
import React from 'react';
import classNames from 'classnames';
import Button from '@/components/Button/Button';
import Strand from '@/components/Strand/Strand';
import LanguagePill from '@/components/LanguagePill/LanguagePill';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { stickerMap } from '@/components/Stickers';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// Tailwind colors
const tailwindConfig = require('../../../tailwind.config.js');



function ResourcesBlock(props) {
    const { value: { resources, heading } } = props;
    // return (
    //     <section className="">
    //         <ResourcesList resources={resources} title={heading ? heading : 'Preview Resources'} showButton={false} showLinks={false}/>
    //     </section>
    // );
    let resourceSwiper;
    return (
        <section className="mt-12">
            <div className="flex mb-8">
                <h2 className="mr-8 text-home-copy">{heading}</h2>
                <Button
                    className="ml-auto swiper-next"
                    onClick={() => resourceSwiper.slideNext()}
                    icon={<ChevronRightIcon />}
                    type="secondary"
                    size="small">
                    Next
                </Button>
            </div>
            <Swiper
                slidesPerView={2}
                spaceBetween={15}
                onInit={(ev) => {
                    resourceSwiper = ev;
                }}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    850: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                }}
                className="w-full">
                {resources.map((resourceItem, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <a href={resourceItem.url + '?freebie=true'}
                                className={classNames([
                                    'comment-item',
                                    'bg-' + resourceItem.bgColour,
                                    'flex flex-col w-full snap-start aspect-square rounded-xl text-large-copy lg:text-home-copy',
                                ])}>
                                <figure
                                    className="flex flex-shrink-0 items-center overflow-hidden justify-center rounded-md transition-all shadow-none group-hover:-translate-y-0.5 group-hover:shadow-lg aspect-square object-cover min-w-full min-h-full"
                                    style={{ backgroundColor: `var(--color-${resourceItem.stickerBg ? resourceItem.stickerBg : 'granite'})` }}>
                                    {resourceItem.image?.file && <img src={resourceItem.image?.url} className="object-cover min-w-full min-h-full" />}
                                    {Object.keys(stickerMap).includes(resourceItem.stickerImage) && !resourceItem.image?.file && (
                                        <div className="transition-all group-hover:animate-wiggle">
                                            {React.createElement(stickerMap[resourceItem.stickerImage], {
                                                className: 'w-32 transition-all drop-shadow-none group-hover:drop-shadow-lg',
                                                color: resourceItem.stickerColor,
                                            })}
                                        </div>
                                    )}
                                </figure>
                                <div className="mb-auto mt-4">
                                    <p>{resourceItem.title}</p>
                                </div>
                                <div className="flex text-small-copy">
                                    <div className="flex flex-wrap items-center gap-3">
                                    <Strand strands={resourceItem.strands} />
                                    {resourceItem.languages && (
                                        <ul className="inline-flex items-center gap-1">
                                            {resourceItem.languages.map((lang) => (
                                                <React.Fragment key={lang.language.code}>
                                                    {lang?.language && (
                                                        <li>
                                                            <LanguagePill lang={lang.language.code} />
                                                        </li>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </ul>
                                    )}
                                    </div>
                                </div>
                            </a>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </section>
    );
  }
  
  export default ResourcesBlock;